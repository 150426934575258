// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-empresa-js": () => import("./../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-onde-comprar-js": () => import("./../src/pages/onde-comprar.js" /* webpackChunkName: "component---src-pages-onde-comprar-js" */),
  "component---src-pages-preview-news-js": () => import("./../src/pages/preview/news.js" /* webpackChunkName: "component---src-pages-preview-news-js" */),
  "component---src-pages-preview-page-js": () => import("./../src/pages/preview/page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../src/pages/preview/post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-preview-produtos-js": () => import("./../src/pages/preview/produtos.js" /* webpackChunkName: "component---src-pages-preview-produtos-js" */),
  "component---src-pages-produtos-js": () => import("./../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-quero-vender-js": () => import("./../src/pages/quero-vender.js" /* webpackChunkName: "component---src-pages-quero-vender-js" */),
  "component---src-pages-quero-vender-quero-ser-um-distribuidor-js": () => import("./../src/pages/quero-vender/quero-ser-um-distribuidor.js" /* webpackChunkName: "component---src-pages-quero-vender-quero-ser-um-distribuidor-js" */),
  "component---src-pages-quero-vender-quero-ser-um-representante-js": () => import("./../src/pages/quero-vender/quero-ser-um-representante.js" /* webpackChunkName: "component---src-pages-quero-vender-quero-ser-um-representante-js" */),
  "component---src-pages-quero-vender-quero-ser-um-revendedor-js": () => import("./../src/pages/quero-vender/quero-ser-um-revendedor.js" /* webpackChunkName: "component---src-pages-quero-vender-quero-ser-um-revendedor-js" */),
  "component---src-templates-blog-author-js": () => import("./../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-single-js": () => import("./../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-blog-tag-js": () => import("./../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-fale-conosco-js": () => import("./../src/templates/fale-conosco.js" /* webpackChunkName: "component---src-templates-fale-conosco-js" */),
  "component---src-templates-news-author-js": () => import("./../src/templates/news-author.js" /* webpackChunkName: "component---src-templates-news-author-js" */),
  "component---src-templates-news-category-js": () => import("./../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-single-js": () => import("./../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-news-tag-js": () => import("./../src/templates/news-tag.js" /* webpackChunkName: "component---src-templates-news-tag-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-category-js": () => import("./../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-single-js": () => import("./../src/templates/product-single.js" /* webpackChunkName: "component---src-templates-product-single-js" */)
}

